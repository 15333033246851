import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResultSet } from '../../models/result-set';
import { User } from '../../models/user';
import { UserManager } from '../../services/user-mananger.service';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private http: HttpClient, private userManager: UserManager) {}

  getAll(page?: number, limit = 40) {
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${page * limit}`);
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/', { params });
  }

  getUser(id: number) {
    return this.http.get<User>(environment.apiBaseUrl + 'users/' + id + '/');
  }

  getBulk(ids: number[]): Observable<ResultSet> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => (params = params.append('id', '' + id)));
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/', { params: params });
  }

  getActivityFeed(userId: number, cursor?: string) {
    if (cursor) {
      return this.http.get<ResultSet>(cursor);
    }

    const params = new HttpParams().set('user', `${userId}`);
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'feed/', { params });
  }

  updateProfile(user: User) {
    return this.http.patch<User>(environment.apiBaseUrl + 'me/', user).pipe(
      mergeMap((res) => {
        this.userManager.updateUser(res);

        return of(res);
      })
    );
  }

  follow(id: number) {
    return this.http.put(environment.apiBaseUrl + 'users/' + id + '/follow/', {});
  }

  unfollow(id: number) {
    return this.http.delete(environment.apiBaseUrl + 'users/' + id + '/follow/', {});
  }

  doesFollow(id: number) {
    return this.http.get(environment.apiBaseUrl + 'users/' + id + '/follows/', {});
  }

  changePassword(oldPassword: string, newPassword: string, repeatPassword: string) {
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      repeatPassword: repeatPassword,
    };

    return this.http.put(environment.apiBaseUrl + 'me/change-password/', data);
  }

  addCorporationManaged(corporation) {
    return this.http.put(environment.apiBaseUrl + 'corporation-managed/' + corporation + '/add_corporation/', {});
  }

  removeCorporationManaged(corporation) {
    return this.http.delete(environment.apiBaseUrl + 'corporation-managed/' + corporation + '/remove_corporation/', {});
  }

  getOwnCollections(userId: number, offset?, limit = 40) {
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${offset}`);
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/' + userId + '/collections/', { params });
  }

  getLikedCollections(userId: number, offset?, limit = 40) {
    const params = new HttpParams().set('limit', `${limit}`).set('offset', `${offset}`);
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/' + userId + '/liked-collections/', { params });
  }

  getStartupsManaged(user, offset, limit, company_name) {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${offset}`);
    if (company_name !== '' && company_name !== null) {
      params = params.set('company_name', company_name);
    }

    return this.http.get<any>(environment.apiBaseUrl + 'startups-managed/' + user.id + '/manage/', { params });
  }

  getCorporationManaged(user, offset, limit, corporation_name) {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${offset}`);
    if (corporation_name !== '' && corporation_name !== null) {
      params = params.set('corporation_name', corporation_name);
    }

    return this.http.get<any>(environment.apiBaseUrl + 'corporation-managed/' + user.id + '/manage/', { params });
  }

  getVCManaged(user, offset, limit, corporation_name) {
    let params = new HttpParams().set('limit', `${limit}`).set('offset', `${offset}`);
    if (corporation_name !== '' && corporation_name !== null) {
      params = params.set('corporation_name', corporation_name);
    }

    return this.http.get<any>(environment.apiBaseUrl + 'vc-managed/' + user.id + '/manage/', { params });
  }

  addStartupToCollection(collectionId, startupId, position) {
    return this.http.post(environment.apiBaseUrl + 'collections/' + collectionId + '/startups/', {
      startup: startupId,
      position: position,
    });
  }

  getDealflows(userId: number) {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'users/' + userId + '/dealflows/');
  }
}
