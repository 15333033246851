import { Pipe, PipeTransform } from '@angular/core';
import { SmartAlert } from '../../../models/smart-alert';
import { SmartFilterQuery } from '../../../models/smart-filter-query';

@Pipe({
  name: 'smartAlertRecipe',
})
export class SmartAlertRecipePipe implements PipeTransform {
  makeSentenceList(values: string[], prefix?: string) {
    let sentence = '';

    if (prefix) {
      sentence = prefix + ' ';
    }

    if (values.length === 1) {
      return sentence + values[0];
    }

    const lastValue = values.pop();

    sentence += values.join(', ');
    sentence += ' & ' + lastValue + ' ';

    return sentence;
  }

  transform(smartAlertQueries: SmartFilterQuery[]): any {
    const values: { [index: string]: [string] } = {};

    smartAlertQueries.forEach((query) => {
      if (values[query.lookup_field]) {
        values[query.lookup_field].push(query.target_object_name);
      } else {
        values[query.lookup_field] = [query.target_object_name];
      }
    });

    let tags = [];

    if (values['tags']) {
      tags = tags.concat(values['tags']);
    }

    if (values['verticals']) {
      tags = tags.concat(values['verticals']);
    }

    let tagText = '';
    if (tags.length > 0) {
      tagText = this.makeSentenceList(tags);
    }

    let investmentText = '';

    if (values['vc_investors']) {
      investmentText = this.makeSentenceList(values['vc_investors'], 'with investment from');
    }

    let locationText;

    if (values['country']) {
      locationText = this.makeSentenceList(values['country'], 'in');
    } else {
      locationText = 'across the world';
    }

    const recipeName = `${tagText} startups ${locationText} ${investmentText}.`.replace('  ', ' ').trim();

    return recipeName;
  }
}
