import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { ResultSet } from '../../../models/result-set';
import { StartupComment } from '../../../models/startup-comment';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class PerformanceService {
  constructor(private http: HttpClient) {}

  getProductivity(userIds: number[], startDate: Date | string, endDate: Date | string) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');

    return this.http.post<any>(`${environment.apiBaseUrl}performance/productivity/`, {
      users: userIds,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getCorporateReport(corporationId: number, startDate: Date | string, endDate: Date | string) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');
    const params = new HttpParams()
      .set('corporation', `${corporationId}`)
      .set('start_date', startDate)
      .set('end_date', endDate);

    return this.http.get<any>(`${environment.apiBaseUrl}performance/corporate/`, { params });
  }

  getCorporateDealflows(corporationId: number, startDate: Date | string, endDate: Date | string, page = 0, limit = 5) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');

    const params = new HttpParams()
      .set('corporation', `${corporationId}`)
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('offset', `${page * limit}`)
      .set('limit', `${limit}`);

    return this.http
      .get<any>(`${environment.apiBaseUrl}performance/corporate/dealflows/`, { params })
      .pipe(
        map((value) => {
          value.results = value.results.map((dealflow) => {
            dealflow.startups = dealflow.startups.map((startup) => {
              return {
                link: ['/discover/startups/', startup.id],
                color: startup.verticals.length > 0 ? startup.verticals[0].color : false,
                title: startup.company_name,
                met: startup.met,
              };
            });
            dealflow.link = ['/dealflow', dealflow.id];
            dealflow.title = dealflow.created;
            dealflow.created = false;

            return dealflow;
          });

          return value;
        })
      );
  }

  getCorporateIntroductions(
    corporationId: number,
    direct: boolean,
    startDate: Date | string,
    endDate: Date | string,
    page = 0,
    limit = 5
  ) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');

    const params = new HttpParams()
      .set('corporation', `${corporationId}`)
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('offset', `${page * limit}`)
      .set('limit', `${limit}`)
      .set('direct', `${direct}`);

    return this.http
      .get<any>(`${environment.apiBaseUrl}performance/corporate/introductions/`, { params })
      .pipe(
        map((value) => {
          value.results = value.results.map((introduction) => {
            introduction.link = ['/discover/startups/', introduction.startup.id];
            introduction.title = introduction.startup.company_name;
            introduction.color =
              introduction.startup.verticals.length > 0 ? introduction.startup.verticals[0].color : false;

            return introduction;
          });

          return value;
        })
      );
  }

  getCorporateCollections(
    corporationId: number,
    startDate: Date | string,
    endDate: Date | string,
    page = 0,
    limit = 5
  ) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');

    const params = new HttpParams()
      .set('corporation', `${corporationId}`)
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('offset', `${page * limit}`)
      .set('limit', `${limit}`);

    return this.http
      .get<any>(`${environment.apiBaseUrl}performance/corporate/collections/`, { params })
      .pipe(
        map((value) => {
          value.results = value.results.map((collection) => {
            collection.link = ['/collections/', collection.id];
            collection.title = collection.collection_name;
            collection.color = false;

            return collection;
          });

          return value;
        })
      );
  }

  getCorporateEvents(corporationId: number, startDate: Date | string, endDate: Date | string, page = 0, limit = 5) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');

    const params = new HttpParams()
      .set('corporation', `${corporationId}`)
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('offset', `${page * limit}`)
      .set('limit', `${limit}`);

    return this.http
      .get<any>(`${environment.apiBaseUrl}performance/corporate/events/`, { params })
      .pipe(
        map((value) => {
          value.results = value.results.map((event) => {
            event.link = ['/events/', event.id];
            event.title = event.event_name;

            return event;
          });

          return value;
        })
      );
  }

  getOwnReport() {
    return this.http.get<any>(`${environment.apiBaseUrl}performance/productivity/report/`);
  }

  getDetailedBreakdown(
    userIds: number[],
    startDate: Date | string,
    endDate: Date | string,
    reportName: string,
    offset: number,
    perPage: number
  ) {
    startDate = moment(startDate).format('LL');
    endDate = moment(endDate).format('LL');

    const params = new HttpParams().set('offset', `${offset}`).set('limit', `${perPage}`);

    return this.http.post<ResultSet>(
      `${environment.apiBaseUrl}performance/productivity/detail/`,
      {
        users: userIds,
        start_date: startDate,
        end_date: endDate,
        report_name: reportName,
      },
      { params }
    );
  }

  getLeaderboard(leaderboard: string, startDate: any, endDate: any, metric?: string) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    startDate = startDate.format('L');
    endDate = endDate.format('L');

    let params = new HttpParams()
      .set('start_date', `${startDate}`)
      .set('end_date', `${endDate}`)
      .set('limit', '100')
      .set('leaderboard', leaderboard);

    if (metric) {
      params = params.append('metric', metric);
    }

    return this.http
      .get<ResultSet>(`${environment.apiBaseUrl}performance/productivity/leaderboard/`, { params })
      .pipe(
        map((res) => {
          res.results = res.results.sort((a, b) => {
            if (a.total < b.total) {
              return 1;
            }

            if (a.total > b.total) {
              return -1;
            }

            return 0;
          });

          return res;
        })
      );
  }
}
