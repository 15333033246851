import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ResultSet } from '../../../models/result-set';

@Injectable()
export class TypeformService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<ResultSet>(environment.apiBaseUrl + 'typeform/');
  }

  createTypeform(typeform) {
    return this.http.post<any>(environment.apiBaseUrl + 'typeform/', typeform);
  }

  updateTypeform(typeform: any) {
    return this.http.patch<any>(environment.apiBaseUrl + 'typeform/' + typeform.form_id + '/', typeform);
  }

  deleteTypeform(typeform: any) {
    return this.http.delete(environment.apiBaseUrl + 'typeform/' + typeform.form_id + '/');
  }

  getTypeformDetails(formId: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}typeform/proxy/?form_id=${formId}`);
  }
}
