import { Pipe, PipeTransform } from '@angular/core';
import { SmartAlertFrequency } from '../../../models/smart-alert-frequency';

@Pipe({
  name: 'smartAlertFrequency',
})
export class SmartAlertFrequencyPipe implements PipeTransform {
  transform(frequency: SmartAlertFrequency): any {
    if (frequency === SmartAlertFrequency.IMMEDIATELY) {
      return 'Immediate';
    } else if (frequency === SmartAlertFrequency.DAILY) {
      return 'Daily';
    } else if (frequency === SmartAlertFrequency.WEEKLY) {
      return 'Weekly';
    } else if (frequency === SmartAlertFrequency.BIWEEKLY) {
      return 'Bi-Weekly';
    } else if (frequency === SmartAlertFrequency.MONTHLY) {
      return 'Monthly';
    }
  }
}
