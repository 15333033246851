import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResultSet } from '../../../models/result-set';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { Project } from '../models/project';
import { ProjectTab } from '../models/project-tab';
import { ProjectWidget } from '../models/project-widget';
import { map } from 'rxjs/operators';
import { Collaborator } from '../../../models/collaborator';
import { User } from '../../../models/user';

@Injectable()
export class ProjectService {
  baseUrl = `${environment.apiBaseUrl}projects/`;

  constructor(private http: HttpClient) {}

  list(filters?: object) {
    return this.http.get<ResultSet>(this.baseUrl);
  }

  create(data: object): Observable<Project> {
    return this.http.post<Project>(this.baseUrl, data);
  }

  get(id: number) {
    return this.http.get<Project>(this.baseUrl + id);
  }

  getWidgetsForTab(projectId, tabId) {
    return this.http.get<ResultSet>(`${this.baseUrl}${projectId}/tabs/${tabId}/widgets/`);
  }

  getTabs(projectId) {
    return this.http.get<ResultSet>(`${this.baseUrl}${projectId}/tabs/`);
  }

  addTab(projectId, tabName, tabOrder) {
    return this.http.post<ProjectTab>(`${this.baseUrl}${projectId}/tabs/`, {
      tab_name: tabName,
      tab_order: tabOrder,
    });
  }

  renameTab(projectId, tabId, newName) {
    return this.http.patch<ProjectTab>(`${this.baseUrl}${projectId}/tabs/${tabId}/`, { tab_name: newName });
  }

  updateTabOrder(projectId, tabId, tabOrder) {
    return this.http.patch<ProjectTab>(`${this.baseUrl}${projectId}/tabs/${tabId}/`, { tab_order: tabOrder });
  }

  deleteTab(projectId, tabId) {
    return this.http.delete(`${this.baseUrl}${projectId}/tabs/${tabId}/`);
  }

  createWidget(projectId, tabId, payload) {
    return this.http.post<ProjectWidget>(`${this.baseUrl}${projectId}/tabs/${tabId}/widgets/`, payload).pipe(
      map((widget) => {
        widget.can_delete = true;
        return widget;
      })
    );
  }

  changeWidgetColumn(projectId, tabId, widgetId, column) {
    return this.http
      .post<ProjectWidget>(`${this.baseUrl}${projectId}/tabs/${tabId}/widgets/${widgetId}`, { column: column })
      .pipe(
        map((widget) => {
          widget.can_delete = true;
          return widget;
        })
      );
  }

  updateWidgetOrders(projectId: number, tabId: number, column: number, widgets: ProjectWidget[]) {
    const mappedWidgets = widgets.map((widget, index) => {
      return {
        id: widget.id,
        position: index,
        column: column,
      };
    });

    return this.http.put(`${this.baseUrl}${projectId}/tabs/${tabId}/widgets/order/`, {
      widgets: mappedWidgets,
    });
  }

  deleteWidget(projectId: number, tabId: number, widgetId: number) {
    return this.http.delete<ProjectWidget>(`${this.baseUrl}${projectId}/tabs/${tabId}/widgets/${widgetId}/`);
  }

  addCollaborator(projectId: number, payload: User) {
    return this.http.post<Collaborator>(`${this.baseUrl}${projectId}/collaborators/`, { collaborator: payload.id });
  }

  updateCollaborator(projectId: number, collaboratorId: number, permission: string) {
    return this.http.patch<Collaborator>(`${this.baseUrl}${projectId}/collaborators/${collaboratorId}/`, {
      permission: permission,
    });
  }

  deleteCollaborator(projectId: number, collaboratorId: number) {
    return this.http.delete(`${this.baseUrl}${projectId}/collaborators/${collaboratorId}/`);
  }

  getCollaborators(projectId: number) {
    return this.http.get<ResultSet<Collaborator>>(`${this.baseUrl}${projectId}/collaborators/`);
  }
}
