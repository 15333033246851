import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StartupsRoutingModule } from './startups-routing.module';
import { PageNotFoundModule } from '../page-not-found/page-not-found.module';
import { PrivateStartupQuery, PrivateStartupStore, PrivateStartupService } from './modules/startup-private/store';
import { ToasterModule } from 'app/shared/modules/toaster/toaster.module';
import { SearchExplorerModule } from '../search/search-explorer.module';
import { UserAdminService } from '../admin/services/user-admin.service';

@NgModule({
  imports: [CommonModule, StartupsRoutingModule, PageNotFoundModule, ToasterModule, SearchExplorerModule],
  providers: [PrivateStartupQuery, PrivateStartupStore, PrivateStartupService, UserAdminService],
})
export class StartupsModule {}
