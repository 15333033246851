import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureGuard } from '../../guards/feature.guard';

const routes: Routes = [
  {
    data: { feature: 'startups', ability: 'list' },
    canActivate: [FeatureGuard],
    path: '',
    loadChildren: () =>
      import('./modules/startup-discover/startup-discover.module').then((m) => m.StartupDiscoverModule),
  },
  {
    data: { feature: 'startups' },
    canActivate: [FeatureGuard],
    path: 'create',
    loadChildren: () => import('./modules/startup-edit/startup-edit.module').then((m) => m.StartupEditModule),
  },
  {
    path: 'invite',
    loadChildren: () =>
      import('./modules/startup-invitation/startup-invitation.module').then((m) => m.StartupInvitationModule),
  },
  {
    data: { feature: 'startups' },
    canActivate: [FeatureGuard],
    path: ':id',
    loadChildren: () => import('./modules/startup-page/startup-page.module').then((m) => m.StartupPageModule),
  },
  {
    data: { feature: 'startups', ability: 'list' },
    canActivate: [FeatureGuard],
    path: 'private/:id',
    loadChildren: () => import('./modules/startup-private/startup-private.module').then((m) => m.StartupPrivateModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class StartupsRoutingModule {}
